import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import { blogList } from "../../data/blogs";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/how-to-choose-a-real-estate-agent-in-san-diego"
  );

  return (
    <LayoutWrapper
      title="How to Choose a Real Estate Agent in San Diego"
      desc="Whether you’re looking to sell your home or need help finding the perfect home to buy, proper representation is crucial to success in San Diego real estate. We are here to help you!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              How to Choose a Real Estate Agent in San Diego
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Feb 02, 2024</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How to Choose a Real Estate Agent in San Diego.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                San Diego is a highly in-demand place to live, with many homes selling above market
                value. Whether you’re looking to sell your home for the right price or you need help
                finding the perfect home to buy, proper representation is crucial to success in San
                Diego real estate.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Why Is a Real Estate Pro Necessary?
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                A great realtor brings skills and expertise to the table that will make all the
                difference in selling or buying a home. This is especially true if your real estate
                pro is well-versed in the San Diego housing market and can provide valuable insight
                into the process.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For sellers, realtors can help you prepare your home for showings and online
                marketing efforts. They can also connect their list of clients with your house to
                find a great buyer faster.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Realtors help buyers find properties matching their wants and needs quickly and
                efficiently. Additionally, your real estate agent will have a much deeper
                understanding of the legal process of closing a deal and purchasing property, so you
                won’t have to spend as much time and energy navigating the details yourself.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Tips for Real Estate Agent Selection
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                How do I choose the right{" "}
                <a
                  href="https://selbysellssd.com/san-diego-real-estate-agents"
                  target="_Blank"
                  className="text-blue-500"
                >
                  real estate agent in San Diego
                </a>
                ? Since your realtor can play such a vital role in the success of your home sale or
                purchase, making your selection can feel like a high-pressure decision. As long as
                you keep these simple tips in mind, you can find the perfect real estate pro to
                exceed your expectations and get the job done well.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Audition Several Options
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you have to switch realtors mid-way through buying or selling a home, it will
                cost you time and money. Even if you decide to stay with a realtor who isn’t
                delivering on their promises, you’ll likely end up overpaying for your new home or
                not even coming close to the price you wanted on the home you’re selling.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Before committing to working with one realtor, take the time to meet with a few.
                This will allow you to get a feel for your options, determine what characteristics
                are essential to you, and feel confident that you’re making the right decision.
                Remember that you’ll be spending a lot of time communicating with your realtor
                during the process, so compatibility on a personal level is also very important.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Ask Your Network for Recommendations
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Don’t hesitate to ask people in your network for the names of realtors they trust.
                With so many real estate agencies in San Diego, picking one can feel like a leap of
                faith.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Knowing that a reliable friend, family member, or coworker has first-hand experience
                with a specific realtor or company can make you feel more comfortable working with
                them. Additionally, your network will likely have a good idea of your particular
                real estate needs, making it possible for them to give you a nuanced recommendation.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Check Their Work in Your Area
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                One of the most valuable skills that realtors bring to the table is expertise.
                Housing markets can also vary greatly from state to state or city to city.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                That’s why the real estate agent you hire should have extensive experience in San
                Diego. Ideally, your realtor should have more than just a few years of working in
                San Diego — they should have at least a few closed deals on their resume.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Trust the Selby Team for Your Real Estate Needs
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Navigating the housing market is tricky enough. Want to make your job easy? Skip the
                back and forth and trust the best in San Diego for your next home purchase or sale.
                <ContactSlideoutLink text="Contact the Selby Team" /> to get started on finding your
                new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
